import { ReactNode } from 'react';
import Attention from '../_icons/Attention';

export const AttentionBox: React.FC<{
	text: string;
	children?: ReactNode;
	className?: string;
	showIcon?: boolean;
}> = props => {
	const { text, children, className, showIcon } = props;
	return (
		<div className="rounded-lg bg-gold-transparent">
			<div className={`flex w-full items-center rounded-lg bg-attention p-6 ${className}`}>
				{showIcon && <Attention className="mr-6" color="white" />}
				<p className="font-bold text-white">{text}</p>
			</div>
			{children ? (
				<div className=" rounded-lg bg-gold-transparent p-6">{children}</div>
			) : null}
		</div>
	);
};
