'use client';
import React, { useTransition } from 'react';
import { Button } from '../../_components/Button';
import { useRouter } from '../../../navigation';
import ArrowRightLong from '../../_icons/Arrow-right-long';

export default function EnglishCoursesNotification() {
	const router = useRouter();

	const [isPending, startTransition] = useTransition();

	return (
		<div className="flex  justify-center rounded-4xl bg-gold-light-32 px-10 py-10 lg:px-40 lg:py-20">
			<div className="flex flex-col items-center space-x-0 space-y-4 lg:flex-row lg:space-x-8 lg:space-y-0">
				<p className="text-sm font-bold leading-6 lg:text-base lg:leading-8">
					There are more courses available in Estonian
				</p>
				<Button
					loading={isPending}
					onClick={e => {
						e.preventDefault();
						startTransition(() => {
							router.replace('/courses', {
								locale: 'et',
							});
						});
					}}
					intent="secondary"
					size="small"
				>
					Switch to Estonian
					<ArrowRightLong />
				</Button>
			</div>
		</div>
	);
}
