import * as React from 'react';
import { SVGProps } from 'react';
interface SVGRProps {
	title?: string;
	titleId?: string;
	desc?: string;
	descId?: string;
}

const ArrowRightLong = ({
	title,
	titleId,
	desc,
	descId,
	...props
}: SVGProps<SVGSVGElement> & SVGRProps) => (
	<svg
		xmlns="http://www.w3.org/2000/svg"
		width={17}
		height={17}
		fill="none"
		aria-labelledby={titleId}
		aria-describedby={descId}
		{...props}
	>
		{desc ? <desc id={descId}>{desc}</desc> : null}
		{title ? <title id={titleId}>{title}</title> : null}
		<path
			d="M16.3536 8.35355C16.5488 8.15829 16.5488 7.84171 16.3536 7.64645L13.1716 4.46447C12.9763 4.2692 12.6597 4.2692 12.4645 4.46447C12.2692 4.65973 12.2692 4.97631 12.4645 5.17157L15.2929 8L12.4645 10.8284C12.2692 11.0237 12.2692 11.3403 12.4645 11.5355C12.6597 11.7308 12.9763 11.7308 13.1716 11.5355L16.3536 8.35355ZM0 8.5H16V7.5H0V8.5Z"
			fill={props.fill ? props.fill : '#293455'}
		/>
	</svg>
);
export default ArrowRightLong;
